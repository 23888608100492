/* 
// TABLE OF CONTENTS //
body
	**** HEADER ****
	header - Header of page
		.navbar - Main navbar/menu
			ul#menu.main-menu - The main menu of the website
				li a - Menu item
			.social-media-top - Class for the social media icons on top of the temaplte
				a i - using ionicons for icons
	**** LOGO ****
		.logobar - class of logo container
			h1.logo - logo
			.contact-info - class fo contact info email & phone
	**** HERO IMAGE ****
	.hero-image - main class that contains the hero image and all its info and text
		.content-wraper
			.content - cotnains the text on the hero image
				.
				.
				.
	**** ALL SECTIONS HAVE THE SAME STRUCTURE ****
	**** REQUEST QUOTE SECTION ****
	.request-quote - Container of section
		.request - container of request inputs
		.features - container of the features
	**** ABOUT SECTION ****
	.about-sec - Container of section
	**** CALL NOW SECTION ****
	.call-now - Container of section
	**** GALLERY SECTION ****
	.gallery-sec - Container of section
		- Structure used to add hover to images
		a.img-wrap
			img
			.img-hover - Add hover color here and style
	**** MENU SECTION ****
	**** TESTIMONIALS SECTION ****
	.testimonials - Container of section
		.flexslider-tes - Using flexslider for testimonials (The same structure for every flexslider)
			.testimonial - single testimonial container
				img - image of client
				p.client-name
				p.testimonial-text
	**** FUN FACTS SECTION ****
	.fun-facts - Container of section
		.fact - single fact container
			i - using ionicons for the icon
			p - title of fact
			p.number - number
	**** PRICING TABLES SECTION ****
	.pricing-tables - Container of section
		.table - single table container
			p.title - title of the table
			ul.body-text - the list of the features in the table
				li - feature 1
				.
				.
				.
	**** NEWS SECTION ****	
	.news-sec - container of section
		.post - single post container
			*** Same structure to add hover images as in Galley section
	**** CONTACT US SECTION ****	
	.contact-sec - container of section
		.container-sm - using this container to take only 80% of the width of the page
			input - to add the info required to contact us
	**** FOOTER ****
		.social-media 
*/
// Css base
@import 'core/vars';
@import 'core/reset';
@import 'core/grid';



// Site
@import 'site/site';
@import 'site/home';
@import 'site/about';
@import 'site/services';
@import 'site/gallery';
@import 'site/news';
@import 'site/news-single';
@import 'site/contact';
 

// Vendors -- PLUGINS
@import 'vendors/slicknav';
@import 'vendors/flexslider';
@import 'vendors/magnific-popup';
@import 'vendors/animate';
@import 'vendors/ionicons';
 
