/* NEWS SECTION */
.news {
	background-color: #fff;
	padding: 100px 0;
	.section-title {
		margin-bottom: 80px;
	}
	.post {
		margin-bottom: 80px;
		@media(max-width: 768px) {
			max-width: 376px;
			margin: auto;
		}
		.img-wrap {
			display: block;
			position: relative;
			img {
				width: 100%;
				margin-bottom: 26px;
			}
			.date-bg {
				position: absolute;
				top: 0;
				right: 0;
				width: 50px;
				height: 64px;
				background-color: $blueColor;
				padding-top: 12px;
				p {
					color: #fff;
					font-family: $fontTwo;
					font-size: 18px;
					font-weight: 700;
					text-align: center;
					span {
						font-weight: normal;
						font-size: 14px;
						display: block;
					}
				}
			}
			@media(max-width: 768px) {
				max-width: 376px;
			}
		}
		.news-title {
			font-size: 18px;
			color: #2e2e2e;
			font-family: $fontTwo;
			font-weight: 700;
			display: inline-block;
			margin-bottom: 14px;
			&:hover {
				color: $blueColor;
			}
		}
	}
	.pagination {
		margin: auto;
		display: block;
		text-align: center;
		.btn {
			padding: 7px 10px;
			border: none;
		}
		.direc.btn {
			padding: 7px 20px;
			border: 1px solid $blueColor;
			&:hover {
				background-color: #2e2e2e;
				border: 1px solid #888888;
			}
			@media(max-width: 460px) {
				display: block;
				max-width: 100px;
				margin: 20px auto;
			}
		}
		.num.btn {
			background-color: transparent;
			border: 1px solid #888888;
			color: #888888;
			&:hover {
				background-color: $blueColor;
				color: #fff;
				border: 1px solid $blueColor;
			}
		}
	}
	@media(max-width: 900px) {
		padding: 100px 30px 100px;
	}
	@media(max-width: 768px) {
		.section-title {
			text-align: center;
		}
		.sec-title-sm {
			text-align: center;
		}
		.post {
			margin-bottom: 50px;
		}
	}
}