/* NEWS SINGLE PAGE */
.news-single {
	background-color: #fff;
	padding: 100px 0;
	/* SIDEBAR */
	.sidebar {
		.sidebar-title {
			font-family: $fontTwo;
			font-size: 18px;
			color: #2e2e2e;
			text-transform: uppercase;
			font-weight: 700;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 18px;
			margin-bottom: 32px;
		}
		.group {
			margin-bottom: 50px;
			ul.side-posts {
				li {
					overflow: hidden;
					img.img-sm {
						float: left;
						margin-right: 14px;
						width: 40%;
						max-width: 100px;
						margin-bottom: 24px;
					}
					.sm-post-title {
						float: left;
						font-size: 14px;
						font-family: $fontTwo;
						color: #2e2e2e;
						font-weight: 700;
						margin-bottom: 6px;
						width: 50%;
						&:hover {
							color: $blueColor;
						}
					}
					.sm-post-date {
						float: left;
						font-family: $fontOne;
						font-size: 12px;
						color: #888888;
						width: 50%;
					}
				}
			}
		}
		.categories {
			margin-bottom: 70px;
			.cetegorie-name {
				font-size: 14px;
				color: #888888;
				margin-bottom: 8px;
				width: 50%;
				width: 100%;
				display: block;
				margin-bottom: 10px;
				&:hover {
					color: $blueColor;
				}
			}
		}
	}
	/* POST */
	.post {
		.post-title {
			font-family: $fontTwo;
			font-size: 30px;
			color: #2e2e2e;
			font-weight: 700;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 5px;
			margin-bottom: 32px;
		}
		.creator {
			font-family: $fontOne;
			font-size: 14px;
			color: #888888;
			margin-bottom: 30px;
			span {
				font-weight: 700;
				color: #2e2e2e;
			}
		}
		img {
			width: 100%;
			margin-bottom: 40px;
		}
		.body-text {
			margin-bottom: 30px;
		}
		blockquote {
			padding: 24px;
			border-left: 5px solid $blueColor;
			margin-bottom: 30px;
			p {
				font-size: 15px;
				color: $blueColor;
				font-style: italic;
				font-weight: 700;
				line-height: 28px;
			}
		}
		.suggestions {
			.title {
				font-family: $fontTwo;
				font-size: 18px;
				color: #2e2e2e;
				text-transform: uppercase;
				font-weight: 700;
				border-bottom: 1px solid #ebebeb;
				padding-bottom: 18px;
				margin-bottom: 50px;
				text-align: center;
				margin-top: 100px;
			}
			.sug-post {
				background-color: #f5f5f5;
				padding: 0 0 30px;
				img {
					margin-bottom: 20px;
				}
				.title-container {
					padding: 0 20px;
					display: block;
					.sm-post-title {
						font-size: 14px;
						font-family: $fontTwo;
						color: #2e2e2e;
						font-weight: 700;
						margin-bottom: 6px;
						&:hover {
							color: $blueColor;
						}
					}
				}
				@media(max-width: 768px) {
					margin-bottom: 40px;
				}
			}
		}
	}
	@media(max-width: 900px) {
		padding: 100px 30px;
	}
}