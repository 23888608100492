// Fonts
$fontOne	: 'Open Sans', sans-serif;
$fontTwo	: 'Play', sans-serif;

// FONT COLORS
$blueColor	: #0169ac;
$textColor	: #888888;
$titleColor	: #2e2e2e;

// BACKROUND COLORS


// FONT SIZES
$titleSize	: 35px;
$textSize	: 14px;


// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

 