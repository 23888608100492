/* CONTACT SECTION */
.contact {
	background-color: #fff;
	padding: 100px 0;
	.sec-title-sm {
		text-align: center;
	}
	.section-title {
		text-align: center;
		margin-bottom: 62px;
	}
	.container-sm {
		width: 80%;
		margin: auto;
		input {
			width: 100%;
			outline: none;
			height: 40px;
			border: none;
			background-color: transparent;
			border: 1px solid #bababa;
			margin-bottom: 24px;
			font-size: 14px;
			color: #888888;
			padding-left: 15px;
			&:focus {
				box-shadow: 0px 2px 4px rgba(255,255,255,.2);
			}
		}
		textarea {
			width: 100%;
			outline: none;
			height: 168px;
			border: none;
			background-color: transparent;
			border: 1px solid #bababa;
			margin-bottom: 32px;
			font-size: 14px;
			color: #888888;
			padding-left: 15px;
			padding-top: 12px;
			resize: none;
			&:focus {
				box-shadow: 0px 2px 4px rgba(255,255,255,.2);
			}
		}
		.btn {
			float: right;
		}
	}
}

/* GOOGLE MAP */
#map {
	height: 370px;
}
#map img {  
    max-width: none;   
} 