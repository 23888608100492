/* HERO IMAGE */
.hero-image {
	background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)),
				url("../images/hero.jpg") no-repeat;
	background-size: cover;
	height: 580px;
	.content-wraper {
		position: relative;
		width: 100%;
		height: 100%;
		.content {
			position: absolute;
			width: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			.big-text {
				font-size: 50px;
				color: #fff;
				font-family: $fontTwo;
				font-weight: 700;
				margin-bottom: 20px;
				@media(max-width: 850px) {
					font-size: 40px;
				}
				@media(max-width: 420px) {
					font-size: 30px;
				}
			}
			.body-text {
				color: #fff;
				width: 60%;
				margin: 0 auto;
				margin-bottom: 62px;
				@media(max-width: 1100px) {
					width: 70%;
				}
			}
			.btn {
				&:hover {
					background-color: #035b94;
					color: #fff;
				}
			}
			.btn-white {
				background-color: #eee;
				color: #888888;
				border-bottom: 3px solid #bababa;
				margin-left: 22px;
				&:hover {
					background-color: #bababa;
					color: #fff;
				}
			}
			@media(max-width: 480px) {
				.body-text {
					margin-bottom: 40px;
				}
				.btn {
					display: inline-block;
					margin: 0 auto 20px;
				}
			}
			@media(max-width: 320px) {
				.body-text {
					margin-bottom: 20px;
				}
				.btn {
					margin: 0 auto 10px;
				}
			}
		}
	}
}

/* REQUEST QUOTE */
.request-quote {
	.container {
		@media(max-width: 970px) {
			padding: 0;
		}
	}
	.request {
		width: 30%;
		background-color: #111111;
		float: left;
		padding: 34px;
		height: 250px;
		p {
			font-size: 20px;
			color: #bababa;
			font-family: $fontTwo;
			text-transform: uppercase;
			margin-bottom: 18px;
		}
		input {
			display: block;
			outline: none;
			width: 100%;
			margin-bottom: 12px;
			height: 34px;
			border: none;
			font-size: 14px;
			color: #888888;
			padding-left: 12px;
		}
		.btn {
			padding: 8px 22px;
			margin-top: 14px;
		}
		@media(max-width: 970px) {
			width: 100%;
			float: none;
			p {
				text-align: center;
			}
			input {
				max-width: 350px;
				margin: 0 auto 10px;
			}
			.btn {
				margin: 20px auto 0;
				text-align: center;
				display: block;
				max-width: 350px;
			}
		}
	}
	.features {
		width: 70%;
		background-color: $blueColor;
		float: right;
		height: 250px;
		padding-top: 60px;
		i {
			font-size: 50px;
			color: #fff;
			display: block;
			text-align: center;
			margin-bottom: 6px;
		}
		p {
			font-size: 18px;
			color: #fff;
			font-family: $fontTwo;
			text-align: center;
		}
		@media(max-width: 970px) {
			width: 100%;
			float: none;
		}
		@media(max-width: 768px) {
			height: auto;
			padding: 44px 0 10px;
			.one-f {
				margin-bottom: 50px;
			}
		}
	}
}

/* ABOUT SECTION */
.about-sec {
	background-color: #fff;
	padding: 100px 0;
	.section-title {
		margin-bottom: 50px;
	}
	.one-col {
		width: 40%;
		float: left;
		margin-right: 50px;
		@media(max-width: 950px) {
			float: none;
			width: 100%;
		}
	}
	.two-col {
		.body-text {
			margin-bottom: 30px;
		}
		.feature-group {
			.feat-group {
				float: left;
				margin-right: 120px;
				&:last-child {
					margin-right: 0;
				}
				.feat {
					position: relative;
					font-style: italic;
					color: $blueColor;
					font-weight: 700;
					font-size: 14px;
					margin-bottom: 16px;
					i {
						margin-right: 10px;
						font-size: 16px;
					}
				}
				@media(max-width: 400px) {
					margin-right: 0;
				}
			}
		}
		@media(max-width: 950px) {
			width: 100%;
		}
	}
	@media(max-width: 950px) {
		text-align: center;
		padding: 100px 30px;
		.section-title {
			margin-bottom: 60px;
		}
		img {
			margin-bottom: 40px;
		}
		.feature-group {
			display: inline-block;
			text-align: left;
		}
	}
}

/* CALL NOW SECTION */
.call-now {
	background: linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.8)),
				url("../images/call-bg.jpg") no-repeat;
	background-size: cover;
	padding: 80px 0;
	h3 {
		font-size: 30px;
		color: #fff;
		font-family: $fontTwo;
		font-weight: 700;
		margin-bottom: 40px;
	}
	p.body-text {
		position: relative;
		color: #bababa;
		padding-left: 70px;
		font-size: 15px;
		span {
			font-weight: 700;
			display: block;
		}
		i {
			position: absolute;
			left: 0;
			top: 6px;
			font-size: 46px;
			color: #fff;
		}
	}
	.btn {
		float: right;
		height: 56px;
		background-color: transparent;
		border: 1px solid #fff;
		padding-top: 18px;
		&:hover {
			color: $blueColor;
		}
		@media(max-width: 370px) {
			padding-bottom: 50px;
		}
	}
	@media(max-width: 768px) {
		padding: 70px 30px 80px;
		p.body-text {
			margin-bottom: 50px;
		}
		.btn {
			float: none;
		}
	}
}

/* GALLERY SECTION */
.gallery-sec {
	background-color: #fff;
	padding: 100px 0;
	.sec-title-sm {
		text-align: center;
	}
	.section-title {
		text-align: center;
		margin-bottom: 80px;
	}
	.container-sm {
		width: 80%;
		margin: auto;
		.img-wrap {
			position: relative;
			max-width: 298px;
			display: block;
			margin-bottom: 30px;
			outline: none;
			&:hover .img-hover {
				background-color: rgba(0,0,0,.5);
				transition: all .4s;
				i {
					opacity: 1;
					transition: all .4s;
				}
			}
			.img-hover {
				transition: all 0.5s ease;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0);
				i {
					transition: all 0.5s ease;
					font-size: 50px;
					color: #fff;
					opacity: 0;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
			}
			@media(max-width: 768px) {
				margin: 0 auto 30px;
			}
		}
	}
	@media(max-width: 768px) {
		padding: 100px 0 84px;
	}
}

/* TESTIMONIALS/WHAT PEOPLE SAY SECTION */
.testimonials {
	background: linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.8)),
				url("../images/tes-bg.jpg") no-repeat;
	background-size: cover;
	padding: 80px 0;
	.section-title {
		margin-bottom: 70px;
		text-align: center;
		color: #fff;
	}
	.flexslider-tes {
		max-width: 850px;
		display: block;
		margin: auto;
		color: #fff;
		font-size: 17px;
		.testimonial {
			background-color: #fff;
			padding: 60px 54px;
			margin-bottom: 30px;
			img.client {
				border-radius: 100%;
				display: block;
				border: 1px solid #d8d8d8;
				box-shadow: 1px 0 6px rgba(0,0,0,.1);
				float: left;
				margin-right: 34px;
				@media(max-width: 768px) {
					float: none;
					margin: auto;
					margin-bottom: 30px;
				}
			}
			.testimonial-txt {
				color: #888888;
				font-size: 14px;
				line-height: 28px;
				font-style: italic;
				@media(max-width: 768px) {
					text-align: center;
				}
			}
			.client-name {
				font-size: 24px;
				color: $blueColor;
				font-weight: 700;
				font-style: italic;
				margin-bottom: 16px;
				@media(max-width: 768px) {
					text-align: center;
				}
			}
		}
		.flex-control-paging li a {
			width: 60px;
			height: 6px;
			background-color: #bababa;
			border-radius: 0;
			&:hover {
				background-color: $blueColor;	
			}
		}
		.flex-control-paging .flex-active {
			background-color: $blueColor;
		}
	}
	@media(max-width: 900px) {
		padding: 100px 30px;
	}
}

/* FUN FACTS */
.fun-facts {
	background-color: $blueColor;
	padding: 40px 0;
	.fact {
		text-align: center;
		i {
			font-size: 50px;
			color: #fff;
			margin-bottom: 8px;
			display: block;
		}
		p {
			color: #fff;
			font-size: 16px;
			font-family: $fontTwo;
			margin-bottom: 8px;
		}
		.number {
			font-size: 30px;
			font-family: $fontTwo;
			font-weight: 700;
		}
	}
	@media(max-width: 900px) {
		padding: 40px 30px 6px;
		[class*='col-'] {
			width: 50%;
		}
		.fact {
			margin-bottom: 50px;
		}
	}
	@media(max-width: 550px) {
		[class*='col-'] {
			width: 100%;
		}
	}
}

/* PRICING TABLES */
.pricing-tables {
	background-color: #ebebeb;
	padding: 100px 0;
	.sec-title-sm {
		text-align: center;
	}
	.section-title {
		text-align: center;
		margin-bottom: 80px;
	}
	.container-sm {
		width: 80%;
		margin: 0 auto;
		.table {
			background-color: #fff;
			padding: 30px 0;
			text-align: center;
			box-shadow: 0 2px 5px rgba(0,0,0,.1);
			span {
				display: block;
				width: 100%;
				height: 70px;
				background-color: $blueColor;
				margin-bottom: 30px;
				font-family: $fontTwo;
				color: #fff;
				font-size: 40px;
				font-weight: 700;
				padding-top: 10px;
			}
			span.standard {
				background-color: #2e2e2e;
			}
			.title {
				font-size: 16px;
				color: $titleColor;
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 16px;
				font-family: $fontTwo;
				margin-bottom: 28px;
			}
			.btn {
				margin-top: 30px;
			}
			.btn.standard {
				background-color: #2e2e2e;
				border-bottom: 3px solid #111111;
			}
		}
	}
	@media(max-width: 950px) {
		[class*='col-'] {
			width: 100%;
		}
		.container-sm .table {
			max-width: 280px;
			margin: auto;
			margin-bottom: 50px;
		}
		padding : 100px 0 70px;
	}
}

/* NEWS SECTION */
.news-sec {
	background-color: #fff;
	padding: 100px 0;
	.section-title {
		margin-bottom: 80px;
	}
	.post {
		@media(max-width: 768px) {
			max-width: 376px;
			margin: auto;
		}
		.img-wrap {
			display: block;
			position: relative;
			img {
				width: 100%;
				margin-bottom: 26px;
			}
			.date-bg {
				position: absolute;
				top: 0;
				right: 0;
				width: 50px;
				height: 64px;
				background-color: $blueColor;
				padding-top: 12px;
				p {
					color: #fff;
					font-family: $fontTwo;
					font-size: 18px;
					font-weight: 700;
					text-align: center;
					span {
						font-weight: normal;
						font-size: 14px;
						display: block;
					}
				}
			}
			@media(max-width: 768px) {
				max-width: 376px;
			}
		}
		.news-title {
			font-size: 18px;
			color: #2e2e2e;
			font-family: $fontTwo;
			font-weight: 700;
			display: inline-block;
			margin-bottom: 14px;
			&:hover {
				color: $blueColor;
			}
		}
	}
	@media(max-width: 900px) {
		padding: 100px 30px 46px;
	}
	@media(max-width: 768px) {
		.section-title {
			text-align: center;
		}
		.sec-title-sm {
			text-align: center;
		}
		.post {
			margin-bottom: 50px;
		}
	}
}

/* CONTACT SECTION */
.contact-sec {
	background: linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.8)),
				url("../images/hero.jpg") no-repeat;
	background-size: cover;
	padding: 100px 0;
	.sec-title-sm {
		text-align: center;
	}
	.section-title {
		color: #fff;
		text-align: center;
		margin-bottom: 62px;
	}
	.container-sm {
		width: 80%;
		margin: auto;
		input {
			width: 100%;
			outline: none;
			height: 40px;
			border: none;
			background-color: #fff;
			margin-bottom: 24px;
			font-size: 14px;
			color: #888888;
			padding-left: 15px;
			&:focus {
				box-shadow: 0px 2px 4px rgba(255,255,255,.2);
			}
		}
		textarea {
			width: 100%;
			outline: none;
			height: 168px;
			border: none;
			background-color: #fff;
			margin-bottom: 32px;
			font-size: 14px;
			color: #888888;
			padding-left: 15px;
			padding-top: 12px;
			resize: none;
			&:focus {
				box-shadow: 0px 2px 4px rgba(255,255,255,.2);
			}
		}
		.btn {
			float: right;
		}
	}
}