/* SERVICES */
.services-sec {
	background-color: #fff;
	padding: 100px 0 10px;
	.section-title {
		margin-bottom: 80px;
	}
	.service {
		margin-bottom: 80px;
		@media(max-width: 768px) {
			max-width: 376px;
			margin: auto;
		}
		.img-wrap {
			display: block;
			position: relative;
			img {
				width: 100%;
				margin-bottom: 26px;
			}
			@media(max-width: 768px) {
				max-width: 376px;
			}
		}
		.serv-title {
			font-size: 18px;
			color: #2e2e2e;
			font-family: $fontTwo;
			font-weight: 700;
			margin-bottom: 14px;
		}
	}
	@media(max-width: 900px) {
		padding: 100px 30px 46px;
	}
	@media(max-width: 768px) {
		.section-title {
			text-align: center;
		}
		.sec-title-sm {
			text-align: center;
		}
		.service {
			margin-bottom: 50px;
		}
	}
}