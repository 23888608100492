/* OVERALL */
a {
	text-decoration: none;
}

img {
	vertical-align: middle;
}

body {
	font-family: $fontOne;
}

.section-title {
	font-family: $fontTwo;
	font-size: $titleSize;
	font-weight: 700;
	color: $titleColor;
	margin-bottom: 30px;
	letter-spacing: 0.7px;
	@media(max-width: 480px) {
		font-size: 26px;
	}
}

.body-text {
	font-family: $fontOne;
	font-size: $textSize;
	color: $textColor;
	line-height: 26px;
}

.sec-title-sm {
	font-family: $fontOne;
	font-size: 15px;
	color: #bababa;
	text-transform: uppercase;
	font-weight: 700;
}

/* BUTTON */
.btn {
	background-color: $blueColor;
	padding: 14px 36px;
	text-align: center;
	max-width: 100%;
	text-transform: uppercase;
	font-size: 14px;
	color: #fff;
	font-family: $fontTwo;
	font-weight: 700;
	letter-spacing: 1px;
	transition: all 0.5s ease;
	border-radius: 3px;
	border-bottom: 3px solid #035b94;
	display: inline-block;
}

/* BUTTON ANIMATIONS */
/* USING Hover.css open source */
/* Sweep To Top */
.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #035b94;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top.gray:before {
  background: #bababa;
}
.hvr-sweep-to-top.white:before {
  background: #fff;
}
.hvr-sweep-to-top.dark:before {
  background: #111111;
}
.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* NAVBAR */
.navbar {
	background-color: #111111;
	.main-menu {
		float: left;
		font-family: $fontTwo;
		> li {
			float: left;
			padding: 20px 30px;
			transition: all 0.5s ease;
			&:hover {
				background-color: #2e2e2e;
			}
			a {
				color: #bababa;
				font-size: 16px;
			}
			@media(max-width: 900px) {
				padding: 20px 20px;
			}
		}
		> li.active {
			background-color: $blueColor;
			a {
				color: #fff;
			}
		}
		@media(max-width: 768px) {
			display: none;
		}
	}
	.social-media-top {
		float: right;
		margin-top: 14px;
		a {
			background-color: transparent;
			border: 1px solid #2e2e2e;
			width: 30px;
			height: 30px;
			display: inline-block;
			text-align: center;
			padding-top: 4px;
			color: #888888;
			transition: all .5s ease;
			&:hover {
				border: 1px solid $blueColor;
				background-color: $blueColor;
				color: #fff;
			}
		}
	}
	@media(max-width: 768px) {
		position: relative;
		.social-media-top {
			float: left;
			position: fixed;
			z-index: 999;
			top: 0;
			left: 40px;
		}
	}
}

/* LOGO & CONTACT INFO */
.logobar {
	background-color: #fff;
	padding: 16px 0;
	h1.logo {
		text-indent: -9999px;
		font-family: $fontOne;
		a {
			background: url("../images/logo.png") no-repeat;
			width: 250px;
			height: 40px;
			float: left;
			margin-top: 14px;
		}
	}
	.contact-info {
		float: right;
		p {
			position: relative;
			color: $titleColor;
			font-family: $fontTwo;
			font-size: 16px;
			line-height: 34px;
			padding-left: 34px;
			i {
				position: absolute;
				left: 0;
				top: 0;
				font-size: 22px;
				color: $blueColor;
			}
		}
		@media(max-width: 768px) {
			display: none;
		}
	}
	@media(max-width: 768px) {
		padding: 16px 20px 30px;
		margin-top: 60px;
	}
}

/* HERO IMAGE */
.hero-image-sec {
	background: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)),
				url("../images/sec-bg.jpg") no-repeat;
	background-size: cover;
	height: 240px;
	.content-wraper {
		position: relative;
		width: 100%;
		height: 100%;
		.content {
			position: absolute;
			width: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			.big-text {
				font-size: 50px;
				color: #fff;
				font-family: $fontTwo;
				font-weight: 700;
				@media(max-width: 850px) {
					font-size: 40px;
				}
				@media(max-width: 420px) {
					font-size: 30px;
				}
			}
		}
	}
}

/* FOOTER */
footer {
	background-color: #000;
	padding: 36px 0;
	p {
		float: left;
		color: #888888;
		font-size: 13px;
	}
	.social-media {
		float: right;
		a {
			color: #fff;
			margin-left: 16px;
			font-size: 14px;
			&:hover {
				color: $blueColor;
			}
		}
	}
	@media(max-width: 1200px) {
		padding: 20px 50px;
	}
	@media(max-width: 480px) {
		padding: 40px 20px;
		p {
			float: none;
			text-align: center;
			margin-bottom: 20px;
		}
		.social-media {
			float: none;
			text-align: center;
			a:first-child {
				margin-left: 0;
			}
		}
	}
}