/* MEET THE TEAM */
.team-sec {
	background-color: #fff;
	padding: 100px 0;
	.sec-title-sm {
		text-align: center;
	}
	.section-title {
		text-align: center;
		margin-bottom: 70px;
	}
	.container-sm {
		width: 90%;
		margin: 0 auto;
		.team-member {
			background-color: #f5f5f5;
			max-width: 298px;
			text-align: center;
			padding-bottom: 30px;
			margin: 0 auto;
			img {
				margin-bottom: 26px;
			}
			.mem-name {
				font-family: $fontTwo;
				font-size: 20px;
				color: #2e2e2e;
				font-weight: 700;
				margin-bottom: 4px;
			}
			.body-text {
				font-size: 13px;
				margin-bottom: 20px;
				text-transform: uppercase;
			}
			.social-med {
				a {
					width: 30px;
					height: 30px;
					display: inline-block;
					border-radius: 100%;
					padding-top: 5px;
					margin-right: 5px;
					i {
						font-size: 16px;
						color: #fff;
					}
				}
				a.fb {
					background-color: #425f9c;
					&:hover {
						background-color: #2e2e2e;
					}
				}
				a.tw {
					background-color: #2089cb;
					&:hover {
						background-color: #2e2e2e;
					}
				}
				a.g {
					background-color: #de4e43;
					&:hover {
						background-color: #2e2e2e;
					}
				}
			}
			@media(max-width: 768px) {
				margin-bottom: 50px;
			}
		}
	}
}
